<template>
  <div>
    <v-card>
      <v-card-title class="d-flex bd-highlight align-center flex-wrap">
        Rentabilidade CPF
      </v-card-title>
      <v-divider class="mt-0"></v-divider>

      <v-card-text class="d-flex align-center flex-wrap mt-5 pb-0">
        <v-row class="mb-3">
          <v-col
            cols="12"
            md="4"
            class="pb-1"
          >
            <v-autocomplete
              v-model="vendorCpf"
              :items="listVendors[0]"
              item-text="name"
              item-value="cpf"
              label="CPF"
              outlined
              dense
              hide-details
              cols="12"
              md="3"
              sm="4"
              lg="3"
              class="pb-1"
              @input="onInput"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="dataTable"
        loading-text="Carregando dados..."
        :loading="loading"
        disable-sort
      >
        <template
          v-slot:no-data
        >
          <span :class="classNoDataText">{{ noDataText }}</span>
        </template>
        <template
          v-slot:group.header="{items}"
        >
          <td
            colspan="10"
          >
            {{ items[0].document }}
          </td>
        </template>
        <template #[`item.comission_total`]="{ item }">
          {{ item.comission_total ? Number(item.comission_total).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : "Não existe" }}
        </template>
        <template #[`item.commission_provision`]="{ item }">
          {{ item.commission_provision ? Number(item.commission_provision).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : "Não existe" }}
        </template>
        <template #[`item.percent`]="{ item }">
          {{ item.percent }}%
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import axiosIns from '@/plugins/axios'

export default {
  data() {
    return {
      referenceList: [],
      listVendors: [],
      vendorCpf: '',
      loading: false,
      classNoDataText: '',
      noDataText: '',
      dataTable: [],
      headers: [
        { text: 'NOME', value: 'name', align: 'center' },
        { text: 'COMISS. TOTAL', value: 'comission_total', align: 'center' },
        { text: 'COMISS. PROVISÃO', value: 'commission_provision', align: 'center' },
        { text: 'PERCENTUAL', value: 'percent', align: 'center' },
        { text: 'REFERÊNCIA', value: 'reference', align: 'center' },
        { text: 'VENDAS', value: 'sales', align: 'center' },
        { text: 'MES/ANO', value: 'mesano', align: 'center' },
      ],

    }
  },

  async created() {
    this.getItemsListVendors()
    this.getFilterItems()
    this.noDataText = 'SELECIONE UM FILTRO'
    this.classNoDataText = 'colorWarning'
  },

  methods: {
    async onInput() {
      await this.getFilterItems()
    },
    async getItemsListVendors() {
      await axiosIns.get('api/v1/records/user/index')
        .then(res => {
          this.listVendors.push(res.data.data)
        })
    },
    async getFilterItems() {
      this.loading = true
      this.noDataText = 'NENHUM REGISTRO ENCONTRADO'
      this.classNoDataText = ''

      const filter = {
        vendor_cpf: this.vendorCpf,
      }

      await axiosIns.post('/api/v1/integration/contract/portfolio_returnity', filter)
        .then(res => {
          this.dataTable = res.data.data
          this.loading = false
        })

      this.loading = false
    },
  },
}
</script>
<style>
  .colorWarning {
    color: #ff9800 !important;
  }
  </style>
